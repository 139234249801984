import React from "react"
import "../css/telecom-bundle-style.css";
import "../css/telecom-bundle-form.css";
import SEO from "../components/common/seo"
import LayoutFourth from "../components/common/layout-fourth"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import $ from "jquery";

const TelecomBundlePage = ({ pageContext, data }) => {
    const scrollToTop = async (e) => {
        e.preventDefault()

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    function initializeHubspotForm() {
        if (typeof window !== "undefined" && "hbspt" in window) {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "20249583",
                formId: "eb3da85e-ed4f-40dd-8361-84d7710ee9a0",
                target: "#rad-form-container",
                onFormSubmit: ($form) => {
                    console.log($form);
                    $("#modal-respuesta").modal('show');
                }
            })
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }


    initializeHubspotForm()

    return (
        <LayoutFourth pageContext={pageContext} cookies={data.cookies}>
            <SEO title={'Schedule a personalized demo'}
                 description={'Tecalis offers a wide range of software solutions designed to boost telecommunications businesses’ growth, expansion, and productivity.'} />

            <header className="mastheadd" id="uphome">
                <div className="container h-100">
                    <div>
                        <StaticImage src="../img/telecom-bundle/logo.svg" width={130} alt="tecalis" className="logo-desktop"
                                     style={{ position: "absolute", top: "30px", marginLeft: "0px", zIndex: 9999 }} />
                    </div>

                    <div align="center" className="logo-mobil"><StaticImage src="../img/telecom-bundle/logo.svg" width={130} alt="tecalis" /></div>

                    <div className="row h-100 align-items-center">
                        <div className="col-xl-7 text-left">
                            <div className="row">
                                <div className="col-xl-9">
                                    <h1 className="spacing mt-5">The first Deploy&Go Telecom Software Bundle</h1>

                                    <div className="mt-2">
                                        <span style={{ fontSize: "18px", letterSpacing: ".3px" }}>
                                          Tecalis offers a wide range of software solutions designed to boost telecommunications businesses’ growth, expansion, and productivity.
                                        </span>
                                    </div>

                                    <div className="mt-5">
                                        <h5 className="mt-2 mb-0 text-form-bundle">
                                            <b>See for yourself how our bundle of digital solutions can help you <span
                                                className="text-tecalis">transform your telecom business.</span></b>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5">
                            <br />

                            <div className="card1 card-telecom mt-3 mb-5">
                                <div className="card-body1">
                                    <h5 align="center" className="text-tecalis mb-0 text-form-bundle"><b>Schedule a personalized live demostration</b></h5>

                                    <div id="rad-form-container" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="pt-5 pb-5 benefits">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center mt-0">
                            <div align="center">
                                <h6 align="center" style={{ opacity: .7 }}>Leading Players that already trust Tecalis:</h6>
                                <div className="logowall telecom">
                                    <div align="center" className="logo-wall telecom">
                                        <div><StaticImage src="../logos/vodafone.png" alt="vodafone" /></div>
                                        <div><StaticImage src="../logos/telefonica.png" alt="telefonica" /></div>
                                        <div><StaticImage src="../logos/o2.png" alt="o2" /></div>
                                        <div><StaticImage src="../logos/orange.png" alt="orange" /></div>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />

                            <div align="left" className="row">

                                <div className="col-1 " />

                                <div align="left" className="col-12 col-xl mb-5" data-aos="fade-right">
                                    <blockquote className="blockquote mb-1 mb-md-0">
                                        <StaticImage src="../logos/o2-test.png" alt="o2" height={38} className="mb-2" />

                                        <br />

                                        <StaticImage className="mb-2" src="../img/telecom-bundle/fivestars.png" alt="5 Estrellas" width={70} />
                                        <p className="text-black-80 font-weight-medium spacing mb-4 mb-md-7 testimonials-telecom" style={{ fontSize: "15px" }}>
                                            “Perfect traveling companions for digital projects, because of their agility and complicity with the business.”
                                        </p>

                                        <div className="media">
                                            <StaticImage className="rounded-circle mr-3 mb-2" src="../img/telecom-bundle/user-o2.png" alt="o2" width={38} />

                                            <div className="media-body">
                                                <p className="font-size-lg spacing mb-0" style={{ fontSize: "16px" }}>
                                                    <b>Ignacio Ceña</b>
                                                </p>
                                                <p className="font-size-sm spacing text-muted mb-0 why--op" style={{ fontSize: "14px" }}>
                                                    Head of Operations at O2
                                                </p>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>

                                <div className="col-1" />

                                <div className="col-1 border-left" />

                                <div className="col-12 col-xl mb-5" data-aos="fade-right">

                                    <blockquote className="blockquote mb-1 mb-md-0">
                                        <StaticImage src="../logos/orange-test.png" alt="Orange" height={38} className="mb-2" />

                                        <br />

                                        <StaticImage className="mb-2" src="../img/telecom-bundle/fivestars.png" alt="5 Estrellas" width={70} />

                                        <p className="text-black-80 font-weight-medium spacing mb-4 mb-md-7 testimonials-telecom" style={{ fontSize: "15px" }}>
                                            “It's really as if they were part of Orange, they find the right technology and approach for us to achieve our challenges.”
                                        </p>

                                        <div className="media">
                                            <StaticImage className="rounded-circle mr-3 mb-2" src="../img/telecom-bundle/user-orange.png" alt="Orange" width={38} />

                                            <div className="media-body">
                                                <p className="font-size-lg spacing mb-0" style={{ fontSize: "16px" }}>
                                                    <b>Francisco J. Lérida</b>
                                                </p>

                                                <p className="font-size-sm spacing text-muted mb-0 why--op" style={{ fontSize: "14px" }}>
                                                    New Channels Manager Orange
                                                </p>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-4 pb-5 bg-grey functions2 border-top">
                <div className="container">
                    <br />

                    <div className="row">
                        <div className="col-xl-4 mt-1">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-3">
                                        <div className="card-body4 bg-black">
                                            <h4 align="left" className="font-weight-bold text-white spacing mr-2">
                                                A collection of digital products designed to lead
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-2">
                                        <div className="card-body4">
                                            <h4 align="left" className="font-weight-bold spacing mr-2 lift2">
                                                Customer Identification
                                            </h4>

                                            <p align="left" className="font-size-sm text-black-80 mb-0 spacing">
                                                KYC Onboarding & Registration
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-2">
                                        <div className="card-body4">
                                            <h4 align="left" className="font-weight-bold spacing mr-2 lift2">
                                                Contracting
                                            </h4>

                                            <p align="left" className="font-size-sm text-black-80 mb-0 spacing">
                                                Digital Signature & Communication
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-2">
                                        <div className="card-body4">
                                            <h4 align="left" className="font-weight-bold spacing mr-2 lift2">
                                                Point of Sale Software
                                            </h4>
                                            <p align="left" className="font-size-sm text-black-80 mb-0 spacing">
                                                Tailored & Fully Integrated
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-2">
                                        <div className="card-body4">
                                            <h4 align="left" className="font-weight-bold spacing mr-2 lift2">
                                                User Experience
                                            </h4>
                                            <p align="left" className="font-size-sm text-black-80 mb-0 spacing">
                                                Agility & Refined Interfaces
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card3 mb-3">
                                        <div className="card-body4">
                                            <h4 align="left" className="font-weight-bold spacing mr-2 lift2">
                                                Regulatory Compliance
                                            </h4>
                                            <p align="left" className="font-size-sm text-black-80 mb-0 spacing">
                                                Fraud Prevention & E2E traceability
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 mt-1 text-center" style={{ position: "relative" }}>
                                    <h1 align="center" className="font-weight-bold spacing">Lead the way in your industry</h1>
                                    <p align="center mb-3">Implement a custom-tailored omnichannel solution in less than 2 months.<br />Speed up your time-to-market without
                                        harming your IT’s team’s productivity.</p>

                                    <img src={require("../img/telecom-bundle/ventajas-01-07.svg").default} className="mt-1 img-fluid" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-4 pb-4 functions4 bg-white" id="moreinfo">
                <div className="container">
                    <br />
                    <div className="row">
                        <div className="col-xl-1" />
                        <div align="left" className="col-xl-2 mb-3">
                            <div className="icons text-primary mb-3">
                                <StaticImage src="../img/telecom-bundle/caracteristicas-01.svg" width={50} alt="..." />
                            </div>

                            <h4 className="font-weight-bold spacing ">
                                All in One
                            </h4>

                            <p className="font-size-sm  mb-4 spacing">
                                Digitize your business and streamline workflows and operations with our customized solutions for each sector, area, and operation.
                            </p>
                        </div>

                        <div className="col-xl-2 mb-3">
                            <div className="icons text-primary mb-3">
                                <StaticImage src="../img/telecom-bundle/caracteristicas-02.svg" width={50} alt="..." />
                            </div>

                            <h4 className="font-weight-bold spacing">
                                Adaptable
                            </h4>

                            <p className="font-size-sm mb-4 spacing">
                                We develop and integrate technology in any system, channel, or application. <br />Desktop, Mobile, or App, online or at the point of sale.
                            </p>
                        </div>

                        <div className="col-xl-2 mb-3">
                            <div className="icons text-primary mb-3">
                                <StaticImage src="../img/telecom-bundle/caracteristicas-03.svg" width={50} alt="..." />
                            </div>

                            <h4 className="font-weight-bold spacing">
                                Compliant
                            </h4>

                            <p className="font-size-sm mb-4 spacing">
                                Tecalis solutions are KYC/AML/KYB/eIDAS/<br />SCA/GDPR compliant. <br />Count on the expert Blockchain EU-certified Trust Services Provider.
                                Certified Communication.
                            </p>
                        </div>

                        <div className="col-xl-2 mb-3">
                            <div className="icons text-primary mb-3">
                                <StaticImage src="../img/telecom-bundle/caracteristicas-04.svg" width={50} alt="..." />
                            </div>

                            <h4 className="font-weight-bold spacing">
                                Reliable
                            </h4>

                            <p className="font-size-sm mb-4 spacing">
                                Proven, reliable solutions with controls and features give each of our customers a top 10 experience, security, and efficiency. <br />Just ask us
                                for a demo.
                            </p>
                        </div>

                        <div className="col-xl-2 mb-3">
                            <div className="icons text-primary mb-3">
                                <StaticImage src="../img/telecom-bundle/caracteristicas-05.svg" width={50} alt="..." />
                            </div>

                            <h4 className="font-weight-bold spacing">
                                Configurable
                            </h4>

                            <p className="font-size-sm mb-4 spacing">
                                A world of possibilities in a technology package that has all the functionalities your business needs. <br />The most future-proof and
                                feature-rich solution on the market.
                            </p>
                        </div>

                        <div className="col-xl-1" />

                    </div>

                    <br />
                </div>
            </section>

            <section className="py-4 px-4 functions4" id="moreinfo">
                <div className="row">
                    <div className="col-xl-3" />

                    <div className="col-xl-6 my-4">
                        <h1 align="center" className="text-white">Let our experts show you how to become <span className="text-tecalis">the best leader that you can be</span></h1>
                    </div>
                    <div className="col-xl-3" />
                </div>
            </section>

            <footer className="pt-10 pb-5 mt-auto bg-white">
                <div className="container">
                    <br />
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="row pt-5">
                                <div className="col-lg-12">
                                    <div className="logo-foot">
                                        <a href="https://tecalis.com" target="_blank"><StaticImage src="../img/telecom-bundle/logo.svg" width={100} alt="tecalis" /></a>
                                        <div className="mb-0 spacing mt-1" style={{ fontSize: "18px" }}><b>Enabling Endless Digital Transformation</b></div>
                                        <div className="mb-0 spacing mt-1" style={{ fontSize: "12px" }}>©2021 Tecalis. All rights reserved</div>
                                        <div className="mb-5 spacing mt-0" style={{ fontSize: "12px" }}>
                                            <a href="https://www.tecalis.com/es/legal/condiciones-uso" className="link-black" style={{ borderBottom: "1px solid" }} target="_blank">Conditions
                                                of use</a> | <a href="https://www.tecalis.com/es/legal/privacidad" className="link-black" style={{ borderBottom: "1px solid" }}
                                                                target="_blank">Privacy Notice</a>
                                        </div>
                                        <div className="mb-5 spacing mt-0" style={{ fontSize: "12px" }}>
                                            <a href="https://www.facebook.com/tecalis/" className="link-black" target="_blank">
                                                <i className="fe fe-facebook ml-1 mr-2" style={{ fontSize: "18px" }} />
                                            </a>
                                            <a href="https://twitter.com/tecalis" className="link-black" target="_blank">
                                                <i className="fe fe-twitter ml-1 mr-2" style={{ fontSize: "18px" }} />
                                            </a>
                                            <a href="https://www.linkedin.com/company/tecalis/mycompany/" className="link-black" target="_blank">
                                                <i className="fe fe-linkedin ml-1 mr-2" style={{ fontSize: "18px" }} />
                                            </a>
                                            <a href="https://www.instagram.com/tecalis/" className="link-black" target="_blank">
                                                <i className="fe fe-instagram ml-1 mr-2" style={{ fontSize: "18px" }} />
                                            </a>
                                            <a href="https://www.youtube.com/c/Tecalis" className="link-black" target="_blank">
                                                <i className="fe fe-youtube ml-1 mr-2" style={{ fontSize: "18px" }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4  mobile">
                            <div align="center" style={{ position: "relative", top: "80px" }}>
                                <a href="#" id="jsScroll" onClick={scrollToTop}>
                                    <i className="fe fe-arrow-up text-tecalis mr-1" style={{ fontSize: "24px" }} /><br />
                                    <span className="mb-3 spacing mt-0" style={{ fontSize: "12px" }}>Back to top</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div align="center" style={{ position: "relative", top: "-5px" }}>
                                <StaticImage src="../img/telecom-bundle/9001.png" className="img-fluid opacity--img ml-1 mr-1" width={80} alt="..." />
                                <StaticImage src="../img/telecom-bundle/27001.png" className="img-fluid opacity--img  ml-1 mr-1" width={80} alt="..." />

                                <br /><br />

                                <StaticImage src="../img/telecom-bundle/cer-9001.png" className="img-fluid opacity--img ml-1 mr-1" width={80} alt="..." />
                                <StaticImage src="../img/telecom-bundle/eidas.png" className="img-fluid opacity--img ml-1 mr-1" width={80} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="modal fade" id="modal-respuesta" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ position: "absolute", top: "15px", right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <br />
                            <div className="container py-5">
                                <h3 align="center" className="font-weight-bold mb-3">Thank you for getting in touch! </h3>
                                <h6 align="center" className="text-muted mt-2 mb-4" style={{ letterSpacing: ".3px" }}>One of our colleagues will get back in touch with you
                                    soon!</h6>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutFourth>
    )
}

export default TelecomBundlePage

export const telecomBundlePageQuery = graphql
    `query($lang: String) {
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: {
            eq: "cookies"
        }
    }) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`
