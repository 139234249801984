/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import SEO from "./seo"
import CookieLand from "./cookieland";
import Feder from "./feder";
import ModalDexga from "./modal-dexga";

const LayoutFourth = ({children, pageContext, cookies = null, favicon = 'favicon.ico'}) => {

    /*const data = useStaticQuery(graphql`
    query SiteTitleFourthQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);*/

    return (
        <>
            <Helmet
                defer={false}
                title="Tecalis"
                htmlAttributes={{"lang": pageContext.locale.path.default}}>
                <link rel="icon" href={require('../../images/' + favicon).default}/>
                {/*<script src="https://kit.fontawesome.com/dd5f5ce6f3.js" crossOrigin="anonymous"/>*/}
                <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer/>
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"/>
                <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"/>
                <link rel="stylesheet" href="https://use.typekit.net/acr2trl.css"/>
            </Helmet>

            <SEO lang={pageContext.locale.path.default}/>

            {children}
            <ModalDexga/>
            {cookies && <CookieLand data={cookies} pageContext={pageContext}/>}
            <Feder/>
        </>
    )
};

LayoutFourth.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutFourth
